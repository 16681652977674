.home {
  padding-top: 60px;
  background-image: url("../../img/home.png");
  background-size: cover;
  width: 100%;
  height: auto;
}
.section {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.jfimg {
  padding-top: 20px;
  padding-left: 40px;
  border-radius: 125px 20px 125px 20px;
  width: 35%;
  height: auto;
}
.jfimg:hover {
  transform: scale(1.03);
  transition: 0.3s ease-in-out;
}
.name {
  font-size: 5vmax;
  color: #e6e6e6;
  margin-bottom: 1px;
  text-shadow: 2px 2px 10px black;
  margin-left: -17%;
  @media only screen and (max-width: 611px) {
    margin-left: 0;
  }
}

.position {
  margin-top: 1px;
  color: #e6e6e6;
  font-style: italic;
  font-weight: lighter;
  text-shadow: 2px 2px 10px black;
  margin-left: -17%;

  @media only screen and (max-width: 611px) {
    margin-left: 0;
    margin-bottom: 20%;
  }
}
.aboutTittle {
  font-size: xx-large;
  padding-top: 10px;
  text-align: center;

  color: #e3b041;
  padding-top: 5%;
  text-shadow: 2px 2px 10px black;
}
.about {
  
  background-color: rgb(101, 84, 59);
  border-radius: 40px 4px 40px 4px;
  width: fit-content;
  text-align: justify;
  color: #e6e6e6;
  font-size: 1.7vmax;
  font-weight: 600;
  padding: 1% 5% 1% 5%;
  margin-bottom: 7%;
  margin-left: 1%;
  margin-right: 1%;
  @media only screen and (max-width: 900px) {
    font-size: 2vh;
  }
}

@media (max-width: 767px) {
  .section {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .jfimg {
    min-width: 200px;
  }
  .name {
    padding-top: 7%;
    margin: 0 auto;
  }
  .position {
    margin: 0 auto;
  }
  .about {
    font-size: large;
    padding: 4%;
  }
}
