.skillsBody {
  padding-top: 3%;
  padding-bottom: 3%;
  display: flex;
  justify-content: center;
  margin-top: 4%;
  margin-bottom: 4%;
}
.container {
  width: 90vw;
  display: block;
  justify-content: center;
  align-items: center;
  box-shadow: 2px 2px 10px black;  
  padding-bottom: 4%;
  background-color: rgba(227, 176, 65, 0.3);
  border-radius: 10px;
  border: none;
}
.tittle {
  text-align: center;
  font-size: xx-large;
  color: #f3bc44;
  
  
  @media only screen and (max-width: 1400px) {
    margin-left: 0;
    display: flex;
    justify-content: center;
    padding: 10%;
  }
}
.spanContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
}
.span {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: #ffffff;
  font-weight: bold;
  border-radius: 10px;
  padding: 5px 20px;
}
.span img {
  width: 125px; 
  height: 125px;    
  padding: 5px;   
}
.span:hover {
  transform: scale(1.03);
  transition: 0.2s ease-out;
  box-shadow: 1px 2px 10px rgba(255, 255, 255, 0.6);
}
