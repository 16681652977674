.projects {
  padding: 60px;
  background-color: rgba(23, 22, 22, 0.999);
}
.title {
  text-align: center;
  color: #e3b041;
  text-shadow: 2px 2px 10px black;
  padding-bottom: 2%;
  font-size: xx-large;

  @media only screen and (max-width: 1400px) {
    margin-left: 0;
    display: flex;
    justify-content: center;
    padding: 10%;
  }
}

.portfolioContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
}
.box {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-size: 200%;
  font-weight: bolder;
  color: white;
}
.box a {
  text-decoration: none;
  color: white;
}
.box a:hover {
  color: antiquewhite;
  text-decoration: underline;
}
.img {
  margin-top: 10%;
  margin-bottom: 10%;
}
.img:hover {
  transform: scale(1.03);
  transition: 0.2s ease-out;
  box-shadow: 10px 0px 59px 0px rgba(252, 3, 3, 1);
}
@media (max-width: 767px) {
  .box {
    padding-bottom: 10%;
  }
}
